import './App.css';

import banner from './images/banner.jpg';
// memes
import ape from './images/ape.png';
import apple from './images/apple.png';
import attempt734 from './images/attempt734.png';
import hello from './images/hello.png';
import negative1000 from './images/negative1000.png';
import norizz from './images/norizz.png';

// clickable images
import birdeye from './images/birdeye.png';
import dexscreener from './images/dexscreener.png';
import dextools from './images/dextools.png';
import telegram from './images/telegram.png';
import x from './images/x.png';


function App() {




  return (
    <div className="App">
      <div className="Nav">
        <h1>$RIZZ</h1>
      </div>
      <div className="Banner">
        <img src={banner}/>
      </div>

      <div className="Video">
        <iframe 
          src='https://www.youtube.com/embed/KJBA7DRhyO0' //'https://youtube.com/embed/jzqeunwe7eE' // 'https://www.youtube.com/embed/1jzROE6EhxM' 
          width="100%"
          height="100%"
          frameborder='0'
          allow='autoplay; encrypted-media'
          allowfullscreen
          title='video'
          />
      </div>

      <div className="Filler">
        <h1>$RIZZ IS CULTURE</h1>
        <p>
          $Rizz is a community takeover led by the biggest RIZZLERS on Solana who got the W gyat rizz. 
          If you don't hold $Rizz then you got L skibidi rizz. 
          Come join us to get some skibidi Fortnite rizz.
        </p>
        <p>
          Our mission is to onboard the next billion zoomers onto the Solana blockchain by spreading the gospel of $Rizz.
        </p>
      </div>
      
      
      <div className="Meme-Container">
        <img src={ape}/>
        <img src={apple}/>
        <img src={attempt734}/>
        <img src={hello}/>
        <img src={negative1000}/>
        <img src={norizz}/>

      </div>

      <div className="Filler">
        <h1>$RIZZ IS A LIFESTYLE</h1>
          Token launched November 2023
          <br/>
          1B initial supply
          <br/>
          Rizz was the 2023 Oxford Word of the Year
          <br/>
          Rizz is derived from charisma, but to zoomers, it means so much more
          <br/>
          Crowning jewel of the Zoomerfolio
          <br/><br/>
      </div>

      <div className="Video">
        <iframe 
          src='https://youtube.com/embed/jzqeunwe7eE' // 'https://www.youtube.com/embed/1jzROE6EhxM' 
          width="100%"
          height="100%"
          frameborder='0'
          allow='autoplay; encrypted-media'
          allowfullscreen
          title='video'
          />
      </div>
      <div className="Links-Section">
        <div className="Rectangles">
            <a href='https://dexscreener.com/solana/92mf6jejabcif4kh4zdulwuepsgy5fftbtqv9nnexhob' target='_blank'>
              <img src={dexscreener}/>
            </a>
            <a href='https://www.dextools.io/app/en/solana/pair-explorer/92MF6jEjAbcif4KH4zDULWuePSGy5FFtBTqV9nNexhob?t=1714965450697' target='_blank'>
              <img src={dextools}/>
            </a>
            <a href='https://birdeye.so/token/6Fb84TUdMNAVgwRinLeTgLov8dJnk5yhNt41Xq2a6s4c?chain=solana' target='_blank'>
              <img src={birdeye}/>
            </a>
        </div>
        <div className="Circles">
          <a href='https://t.me/gotrizzfr' target='_blank'>
              <img src={telegram}/>
            </a>
            <a href='https://twitter.com/GotRizzSolana' target='_blank'>
              <img id="x-image" src={x}/>
            </a>
        </div>
      </div>

      {/*<div className="Clickable-Image-Container">
        <div className="Left">

          <div className="Clickable-Image">
            <a href='https://dexscreener.com' target='_blank'>
              <img src={dexscreener}/>
            </a>
          </div>
          <div className="Clickable-Image">
            <a href='https://dexscreener.com' target='_blank'>
              <img src={dextools}/>
            </a>
          </div>
          <div className="Clickable-Image">
            <a href='https://dexscreener.com' target='_blank'>
              <img src={birdeye}/>
            </a>
          </div>

        </div>
        <div className="Right">
          <div className="Clickable-Image">
            <a href='https://dexscreener.com' target='_blank'>
              <img src={x}/>
            </a>
          </div>
          <br/><br/>
          <div className="Clickable-Image">
            <a href='https://dexscreener.com' target='_blank'>
              <img id="telegramLogo" src={telegram}/>
            </a>
          </div>
        </div>
      </div>*/}





    </div>
  );
}

export default App;
